
const CategoryCardLoading = () => {
    return (
        <>
            <div className="cotizar-items loading">
                <p className="loading"></p>
            </div>
        </>
    )
}

export default CategoryCardLoading
